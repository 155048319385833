
import TheTeProHeader from '~/components/Te/TePro/TheTeProHeader/TheTeProHeader'
import TheTeProFooter from '~/components/Te/TePro/TheTeProFooter/TheTeProFooter'

export default {
  components: {
    TheTeProHeader,
    TheTeProFooter,
  },
}
