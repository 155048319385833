
import TheTeCommonHeader from '~/components/Te/TeCommon/Header/TheTeCommonHeader/TheTeCommonHeader'
import TheTeCommonFooterRowOne from '~/components/Te/TeCommon/Footer/TheTeCommonFooterRowOne/TheTeCommonFooterRowOne'

export default {
  components: {
    TheTeCommonHeader,
    TheTeCommonFooterRowOne,
  },
}
