
import TheTeProTrialLpSHeader from '~/components/Te/TePro/LpS/TheTeProTrialLpSHeader'
import TheTeProTrialLpSFooter from '~/components/Te/TePro/LpS/TheTeProTrialLpSFooter'

export default {
  components: {
    TheTeProTrialLpSHeader,
    TheTeProTrialLpSFooter,
  },
}
