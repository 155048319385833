
import TheBuCommonHeaderSimpleLpKonicaminolta from '~/components/Bu/BuCommon/TheBuCommonHeaderSimpleLpKonicaminolta'
import TheTeCommonFooterSimple from '~/components/Te/TeCommon/Footer/TheTeCommonFooterSimple/TheTeCommonFooterSimple'

export default {
  components: {
    TheBuCommonHeaderSimpleLpKonicaminolta,
    TheTeCommonFooterSimple,
  },
}
